import { ACCOUNT } from '../actionTypes';
import { Api, $Api } from '../../utils/connectors';

const getData = (payload) => ({ type: ACCOUNT.GET_DATA, payload });
const setData = (payload) => ({ type: ACCOUNT.SET_DATA, payload });
const deleteData = (payload) => ({ type: ACCOUNT.DELETE_DATA, payload });

//compose actions

const getBasicInfo = (snackBar) => async (dispatch) => {
  try {
    const { data } = await $Api.get('/users');
    dispatch(getData(data));
  } catch (error) {
    snackBar('There was error to getting account info', { variant: 'error' });
  }
};

const updateBasicInfo = (form, snackBar) => async (dispatch) => {
  try {
    const { data } = await $Api.put('/users/changeBasicInfo', form);
    dispatch(setData(form));
    snackBar(data.text || 'Changes were saved!', { variant: 'success' });
  } catch (error) {
    snackBar(error?.response?.data?.error?.message || 'Something went wrong, please try again!', {
      variant: 'error',
    });
  }
};

const updateEmail = (token, email, snackBar) => async (dispatch) => {
  try {
    await $Api.put('/users/confirmationEmail', { token });
    dispatch(setData({ email }));
    snackBar('Changes were saved!', { variant: 'success' });
  } catch (error) {
    snackBar('Something went wrong, please try again!', {
      variant: 'error',
    });
  }
};

const activateAccount = (token, email, snackBar) => async (dispatch) => {
  try {
    await $Api.put('/users/activateAccount', { token });
    dispatch(setData({ activated: 1 }));
    snackBar('Your Account activated!', { variant: 'success' });
  } catch (error) {
    snackBar('Something went wrong, please try again!', {
      variant: 'error',
    });
  }
};

const actions = {
  getData,
  setData,
  deleteData,
  getBasicInfo,
  updateBasicInfo,
  updateEmail,
  activateAccount,
};

export default actions;
