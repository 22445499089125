import React from 'react';

// Load Hooks
import { useSnackbar } from 'notistack';

// Load Components
import Button from './Button';

const SnackbarCloseButton = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  return <Button apperance='text' size='sm' icon='Close' onClick={() => closeSnackbar(id)} />;
};

export default SnackbarCloseButton;
