import React from 'react';

const Toggle = ({ onClick, children }) => {
  return (
    <div className='app-dropdown-toggle' onClick={onClick} role='presentation'>
      {children}
    </div>
  );
};

export default Toggle;
