import React from 'react';

// Load Components
import AppHeader from './components/AppHeader';
import AuthHeader from './components/AuthHeader';
import EditorHeader from './components/EditorHeader';
import StructuralHeader from './components/StructuralHeader';
import OnboardingHeader from './components/OnboardingHeader';
import TemplatesHeader from './components/TemplatesHeader';

// Load Vendors
import classNames from 'classnames';

const getHeaderType = (type, path) => {
  if (/\/templates/.test(path)) type = 'templates';
  if (/[autoresponder|email-marketing]\/create/.test(path)) type = 'structural';
  if (/popup\/manage/.test(path)) type = 'editor';
  return type;
};

const Header = ({ type, className }) => {
  const headers = {
    app: {
      component: AppHeader,
      classes: /audience|campaigns/g.test(window.location.pathname) ? 'bordered' : 'shadowed',
    },
    auth: {
      component: AuthHeader,
      classes: 'shadowed',
    },
    editor: {
      component: EditorHeader,
      classes: 'bordered',
    },
    structural: {
      component: StructuralHeader,
      classes: 'bordered',
    },
    onboarding: {
      component: OnboardingHeader,
      classes: 'bordered',
    },
    templates: {
      component: TemplatesHeader,
      classes: 'shadowed',
    },
  };

  type = getHeaderType(type, window.location.pathname);

  const chunk = headers[type] ?? headers.app;
  const classes = classNames('header', chunk.classes, className);

  const Component = chunk.component;

  return (
    <header className={classes}>
      <div className='container h-100'>
        <Component />
      </div>
    </header>
  );
};

export default Header;
