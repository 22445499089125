import React from 'react';

const Footer = ({ onClick, children }) => (
  <div
    className='h-52-px d-flex align-items-center justify-content-end border-top p-2'
    onClick={onClick}
    role='presentation'
  >
    {children}
  </div>
);

export default Footer;
