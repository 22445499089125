import { useState, useEffect } from 'react';

// Load Apis
import { $Api, Api, setHeaderAuthToken } from 'utils/connectors';

// Load Actions
import AuthActions from 'redux/actions/authActions';
import accountActions from 'redux/actions/accountActions';

// Load Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Load Utils
import { getError } from 'utils/responseHelpers';

// Load Services
import TokenService from 'services/TokenService';

//Load Configs
import { URLHISTORY } from '../configs';

export const useAuth = (init = false) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { isAuthenticated, accountStore: account } = useSelector((state) => state);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState('');

  const _logOutAndCleanup = () => {
    setHeaderAuthToken('', $Api);
    dispatch(AuthActions.setState(null));
    dispatch(AuthActions.logout());
    TokenService.remove();
    TokenService.remove('refresh');
    URLHISTORY.start = `/dashboard/setup/`;
  };

  const _getProfile = async () => {
    try {
      const { data } = await $Api.get('/users/me');
      return data;
    } catch (err) {
      throw new Error(err);
    }
  };

  const _loginUser = async (token, refreshToken) => {
    try {
      TokenService.save(token);
      TokenService.save(refreshToken, 'refresh');
      // setHeaderAuthToken(token, Api);

      const user = await _getProfile();
      dispatch(AuthActions.login(user));
      dispatch(AuthActions.setState(1));
      dispatch(accountActions.setData(user));
      push(
        URLHISTORY.start.includes('/dashboard') || URLHISTORY.start === '/'
          ? '/dashboard/setup'
          : URLHISTORY.start,
      );
    } catch (err) {
      _logOutAndCleanup();
    }
  };

  const _initAuthState = async () => {
    try {
      const token = TokenService.get();
      if (!token) throw new Error();

      // setHeaderAuthToken(token, Api);
      const user = await _getProfile();
      dispatch(AuthActions.login(user));
      dispatch(AuthActions.setState(1));
      dispatch(accountActions.setData(user));
      push(
        URLHISTORY.start.includes('/dashboard') || URLHISTORY.start === '/'
          ? '/dashboard/setup'
          : URLHISTORY.start,
      );
      // }
    } catch (err) {
      _logOutAndCleanup();
    }
  };

  const authenticateUser = async (body) => {
    body['client_id'] = 'popup_maker_rock';
    body['username'] = body['email'];
    body['grant_type'] = 'password';
    try {
      setFetching(true);
      const { data } = await Api.post('/token', body);
      await _loginUser(data.access_token, data.refresh_token);
    } catch (err) {
      setError(err.response.status === 404 ? 'User not found' : getError(err));
      throw err.response.status === 404 ? 'User not found' : getError(err);
    } finally {
      setFetching(false);
    }
  };

  const logout = () => {
    _logOutAndCleanup();
  };

  useEffect(() => {
    if (isAuthenticated === null && init == true) {
      _initAuthState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return {
    // States
    // account,
    isAuthenticated,
    fetching,
    error,

    // Actions
    authenticateUser,
    logout,
  };
};
