import React from 'react';

// Support item types: actiion, checkbox, search

// Load Components
import Button from './_Button';
import Checkbox from './_Checkbox';
import Search from './_Search';
import Notification from './_Notification';

const components = {
  button: Button,
  checkbox: Checkbox,
  search: Search,
  notification: Notification,
};

const Item = ({ onClick, type, ...componentProps }) => {
  const Component = components[type];

  if (!Component) {
    throw new TypeError(`The type should be: [${Object.keys(components)}]`);
  }
  return (
    <div onClick={onClick} role='presentation' className='mb-1'>
      <Component {...componentProps} />
    </div>
  );
};

export default Item;
