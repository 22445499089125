// Load vendors
import { combineReducers } from 'redux';

// Load reducers
import isAuthenticated from './reducers/isAuth';
import campaigns from './reducers/campaigns';
import account from './reducers/account';
import settings from './reducers/settings';
import audience from './reducers/audience';
import statistics from './reducers/statistics';

const rootReducer = combineReducers({
  isAuthenticated,
  campaignStore: campaigns,
  accountStore: account,
  settingsStore: settings,
  audienceStore: audience,
  statisticsStore: statistics,
});

export default rootReducer;
