import React from 'react';

// Load Vendors
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

const NavTabsHorizontal = ({
  items = [],
  size = 'md',
  className,
  itemClass,
  centered,
  border = true,
}) => {
  const classes = cn(
    'nav nav-tabs tabs-horizontal',
    {
      [`tabs-horizontal-${size}`]: 'size',
      'justify-content-center': centered,
      bordered: border,
    },
    className,
  );
  return (
    <ul className={classes}>
      {items.map((item) => (
        <li className={cn('nav-item', itemClass)} key={item.name + item.to}>
          <NavLink
            to={item.to}
            key={item.name}
            className={cn('nav-link', { disabled: item.disabled })}
            disabled={item.disabled}
          >
            {item.name}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default NavTabsHorizontal;
