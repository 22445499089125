export const AUTH = {
  SET_STATE: '@@auth/SET_STATE',
  LOGIN: '@@auth/ON_LOGIN',
  LOGOUT: '@@auth/ON_LOGOUT',
};

export const CAMPAIGNS = {
  SET_DATA: '@@campaigns/SET_DATA',
  GET_POPUPS: '@@campaigns/GET_POPUPS',
  GET_MORE_POPUPS: '@@campaigns/GET_MORE_POPUPS',
  GET_FILTERED_POPUPS: '@@campaigns/GET_FILTERED_POPUPS',
  DELETE_POPUP: '@@campaigns/DELETE_POPUP',
  ENABLE_POPUP: '@@campaigns/ENABLE_POPUP',
};

export const ACCOUNT = {
  GET_DATA: '@@account/GET_DATA',
  SET_DATA: '@@account/SET_DATA',
  DELETE_DATA: '@@account/DELETE_DATA',
};

export const SETTINGS = {
  GET_DATA: '@@settings/GET_DATA',
  SET_DATA: '@@settings/SET_DATA',
  ADD_DATA: '@@settings/ADD_DATA',
  DELETE_DATA: '@@settings/DELETE_DATA',
};

export const AUDIENCE = {
  GET_DATA: '@@audience/GET_DATA',
  DELETE_DATA: '@@audience/DELETE_DATA',
};

export const STATISTICS = {
  GET_DATA: '@@statistics/GET_DATA',
};
