import React from 'react';

// Load Hooks
import { useHistory } from 'react-router-dom';

// Load Components
import Button from 'shared/components/buttons/Button';
import Dropdown from 'shared/components/dropdown/Dropdown';
import HyperLink from 'shared/components/links/HyperLink';

const data = [
  {
    id: 1,
    title: 'New Notification Text',
    date: '3 min. ago',
    read: false,
  },
  {
    id: 2,
    title: 'New Notification Text',
    date: '3 min. ago',
    read: false,
  },
  {
    id: 3,
    title: 'Ina commented on your post',
    date: '3 min. ago',
    read: true,
  },
];

const NotificationsDropdown = () => {
  const { push } = useHistory();

  const navigateItem = (to) => push(to);

  // For demonstration
  const hasNewNotification = true;

  return (
    <Dropdown bodyClass='mt-4' opens='left'>
      <Dropdown.Toggle>
        <Button apperance='text' icon={hasNewNotification ? 'BellCircle' : 'Bell'} />
      </Dropdown.Toggle>
      <Dropdown.Header>
        <div className='h-100 d-flex align-items-center justify-content-between'>
          <p className='text-secondary fw-bold mb-0'>Notifications</p>
          <HyperLink onClick={() => alert('Mark all as read')}>Mark All As Read</HyperLink>
        </div>
      </Dropdown.Header>
      {!!data.length &&
        data.map((item) => <Dropdown.Item type='notification' key={item.id} {...item} />)}
      <Dropdown.Footer>
        <Button
          apperance='text'
          className='mr-1'
          block
          onClick={() => navigateItem('/notifications')}
        >
          See All
        </Button>
      </Dropdown.Footer>
    </Dropdown>
  );
};

export default NotificationsDropdown;
