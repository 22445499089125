import React, { lazy } from 'react';

// Load Vendors
import { Switch, Route, Redirect } from 'react-router-dom';

// Load Hooks
import { useAuth } from 'hooks/useAuth';
import { useActivations } from '../../hooks/useActivations';

// Load Routes
// Public
const UI = lazy(() => import('../routes/UI'));

// Unauthorized
const Login = lazy(() => import('../routes/Login'));
const Register = lazy(() => import('../routes/Register'));
const Forgot = lazy(() => import('../routes/Forgot'));
const Reset = lazy(() => import('../routes/Reset'));
const Logout = lazy(() => import('../routes/Logout'));

// Authorized
const Onboarding = lazy(() => import('../routes/Onboarding'));
const Notifications = lazy(() => import('../routes/Notifications'));
const Account = lazy(() => import('../routes/Account'));
const Integrations = lazy(() => import('../routes/Integrations'));
const Settings = lazy(() => import('../routes/Settings'));
const Billing = lazy(() => import('../routes/Billing'));
const Users = lazy(() => import('../routes/Users'));
const Audience = lazy(() => import('../routes/Audience'));
const Campaigns = lazy(() => import('../routes/Campaigns'));
const Popup = lazy(() => import('../routes/Popup'));
const Autoresponder = lazy(() => import('../routes/Autoresponder'));
const EmailMarketing = lazy(() => import('../routes/EmailMarketing'));
const Statistics = lazy(() => import('../routes/Statistics'));
const Dashboard = lazy(() => import('../routes/Dashboard'));

const Router = () => {
  // Calling the hook to check whether the user is logged in or not
  useActivations();
  const { isAuthenticated } = useAuth(true);
  return (
    <Switch>
      <Route path='/ui' component={UI} />
      {isAuthenticated ? (
        <>
          <Route path='/onboarding' component={Onboarding} />
          <Route path='/notifications/:id?' component={Notifications} />
          <Route path='/account' component={Account} />
          <Route path='/integrations' component={Integrations} />
          <Route path='/settings' component={Settings} />
          <Route path='/billing' component={Billing} />
          <Route path='/users' component={Users} />
          <Route path='/audience' component={Audience} />
          <Route path='/campaigns' component={Campaigns} />
          <Route path='/popup' component={Popup} />
          <Route path='/autoresponder' component={Autoresponder} />
          <Route path='/email-marketing' component={EmailMarketing} />
          <Route path='/statistics/:id' component={Statistics} />
          <Route path='/dashboard' component={Dashboard} />
          <Redirect from='*' to='/dashboard' />
        </>
      ) : (
        <>
          <Route path='/login' component={Login} />
          <Route path='/register' component={Register} />
          <Route path='/forgot' component={Forgot} />
          <Route path='/reset' component={Reset} />
          <Route path='/logout' component={Logout} />
          <Redirect from='*' to='/login' />
        </>
      )}
    </Switch>
  );
};

export default Router;
