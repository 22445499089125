import React from 'react';
import { Link } from 'react-router-dom';

// Load Components
import Button from 'shared/components/buttons/Button';

const TemplatesHeader = () => (
  <div className='d-flex align-items-center justify-content-between h-100'>
    <h4 className='mb-0'>
      <Link to='/campaigns' className='text-gray-900'>
        Select Template
      </Link>
    </h4>
    <div>
      <Button apperance='primary'>Create From Scratch</Button>
      <Button apperance='text' icon='Close' className='ml-2' />
    </div>
  </div>
);

export default TemplatesHeader;
