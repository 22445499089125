import React from 'react';

// Load Hooks
import { useLocation } from 'react-router-dom';

// Load Components
import AuthLayout from './AuthLayout';
import MainLayout from './MainLayout';

// Load Consts
import { REGS } from 'configs';

const AppLayout = ({ children }) => {
  const { pathname } = useLocation();

  if (REGS.mainLayoutExceptions.test(pathname)) return children;

  const isAuthUrl = REGS.authUrls.test(pathname);

  const Layout = isAuthUrl ? AuthLayout : MainLayout;

  return <Layout>{children}</Layout>;
};

export default AppLayout;
