import React from 'react';

// Load Hooks
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

// Load Components
import Avatar from 'shared/components/vendor/Avatar';
import Button from 'shared/components/buttons/Button';
import Dropdown from 'shared/components/dropdown/Dropdown';
import { useSelector } from 'react-redux';
import { REGS } from '../../../../../configs';

const exampleUrl =
  'https://www.hakuhodo-global.com/wp_admin/wp-content/uploads/2017/11/Kazuhiro_Suda2square.jpg';

const UserDropdown = () => {
  const { push } = useHistory();
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    push('/logout');
  };

  const accountStore = useSelector((state) => state.accountStore) ?? {};

  const userFullName = `${accountStore.firstname} ${accountStore.lastname}`;
  return (
    <Dropdown bodyClass='mt-4' opens='left'>
      <Dropdown.Toggle>
        <Avatar
          size='sm'
          placeholder={userFullName}
          //imageURL={exampleUrl}
          className='ml-2 cursor-pointer'
        />
      </Dropdown.Toggle>
      <Dropdown.Item type='button' to='/account'>
        Account
      </Dropdown.Item>
      <Dropdown.Item type='button' to='/settings'>
        Settings
      </Dropdown.Item>
      <Dropdown.Item type='button' to='/integrations'>
        Integrations
      </Dropdown.Item>
      <Dropdown.Item type='button' to='/billing'>
        Billing
      </Dropdown.Item>
      <Dropdown.Item type='button' to='/users'>
        Users
      </Dropdown.Item>
      <Dropdown.Item type='button' to='/support'>
        Support {/* Is external link */}
      </Dropdown.Item>
      <Dropdown.Item type='button' to='/video-tutorials'>
        Video Tutorials {/* Is external link */}
      </Dropdown.Item>
      <Dropdown.Footer>
        <Button apperance='text' className='mr-1' block onClick={handleLogout}>
          Logout
        </Button>
      </Dropdown.Footer>
    </Dropdown>
  );
};

export default UserDropdown;
