import { createReducer } from 'utils/reduxHelpers';
import { SETTINGS } from 'redux/actionTypes';

const initialState = {
  domains: [],
};

const handlers = {
  [SETTINGS.GET_DATA]: (state, action) => ({
    ...state,
    domains: [
      ...action.payload.map((item) => ({
        ...item,
        active: !!+item.active,
        inserted: !!item.inserted,
      })),
    ],
  }),
  [SETTINGS.ADD_DATA]: (state, action) => ({
    ...state,
    domains: [action.payload, ...state.domains],
  }),
  [SETTINGS.SET_DATA]: (state, action) => {
    if (action.payload.action === 'domain') {
      let _domains;

      _domains = state.domains.map((item) => {
        if (item.id === action.payload.id) {
          item.domain = action.payload.domain;
          return { ...item, domain: action.payload.domain };
        }
        return item;
      });

      return {
        ...state,
        domains: _domains,
      };
    }
    if (action.payload.action === 'enable/domain') {
      let _domains;

      _domains = state.domains.map((item) => {
        if (item.id === action.payload.id) {
          // item.active = !action.payload.active;
          return { ...item, active: !item.active };
        }
        return item;
      });

      return {
        ...state,
        domains: _domains,
      };
    }

    return state;
  },
  [SETTINGS.DELETE_DATA]: (state, action) => {
    const domainId = action.payload.id;
    const domains = state.domains.filter((domainObj) => domainObj.id !== domainId);

    return {
      ...state,
      domains,
    };
  },
};

export default createReducer(initialState, handlers);
