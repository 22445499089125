import { useEffect } from 'react';
import { ACTIVATION } from '../configs';
import { parseJwt } from '../utils/appHelpers';
import { useDispatch } from 'react-redux';
import accountActions from '../redux/actions/accountActions';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import TokenService from '../services/TokenService';
export const useActivations = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (ACTIVATION.email) {
      try {
        const newEmail = parseJwt(ACTIVATION.email)['new_email'];
        const oldEmail = parseJwt(ACTIVATION.email)['old_email'];
        dispatch(accountActions.updateEmail(ACTIVATION.email, newEmail, enqueueSnackbar));
        ACTIVATION.email = false;
        push('/account/profile');
      } catch (err) {
        throw new Error(err);
      }
    }
    if (ACTIVATION.registration) {
      try {
        const email = parseJwt(ACTIVATION.registration)['email'];
        dispatch(accountActions.activateAccount(ACTIVATION.registration, email, enqueueSnackbar));
        ACTIVATION.registration = false;
      } catch (err) {
        throw new Error(err);
      }
    }
    if (ACTIVATION.reset && !TokenService.get()) {
      try {
        const token = parseJwt(ACTIVATION.reset)['token'];
        // setTimeout(() => {
        console.log('token', token);
        push('/reset');
        // }, 3000);
      } catch (err) {
        throw new Error(err);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
