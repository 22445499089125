/* eslint-disable prettier/prettier */
import moment from 'moment';

export const REGS = {
  mainLayoutExceptions: /onboarding|ui|logout/gi,
  authUrls: /login|register|forgot|reset|logout/,
};

export const CONSTS = {
  headerNavigation: [
    {
      name: 'Dashboard',
      to: '/dashboard',
    },
    {
      name: 'Campaigns',
      to: '/campaigns',
      childs: [
        {
          name: 'Popups',
          to: '/campaigns/popups',
        },
        {
          name: 'Email Marketing',
          to: '/campaigns/email-marketing',
        },
        {
          name: 'Autoresponder',
          to: '/campaigns/autoresponder',
        },
        {
          name: 'Push Notification',
          to: '/campaigns/push-notification',
        },
      ],
    },
    {
      name: 'Audience',
      to: '/audience',
      childs: [
        {
          name: 'Subscribers',
          to: '/audience/subscribers',
        },
        {
          name: 'Contacts',
          to: '/audience/contacts',
        },
      ],
    },
  ],
  headerNavigationEditor: [
    { name: 'Design', to: '/popup/manage/design' },
    { name: 'Display Rules', to: '/popup/manage/display-rules' },
    { name: 'Integrations', to: '/popup/manage/integrations' },
  ],
  footerNavigation: [
    { name: 'Privacy Policy', to: '/privacy-policy' },
    { name: 'Terms & Conditions', to: '/terms' },
  ],
  pricingClassByType: {
    individual: 'text-astral',
    professional: 'text-carnation',
    enterprise: 'text-warning',
    agency: 'text-info',
  },
  positioningIndexMapping: {
    0: { top: 0, left: 0, bottom: 'unset', right: 'unset', transofrm: 'translate(0, 0)' },
    1: { top: 0, left: '50%', bottom: 'unset', right: 'unset', transofrm: 'translate(-50%, 0)' },
    2: { top: 0, left: 'unset', bottom: 'unset', right: 0, transofrm: 'translate(0, 0)' },
    3: { top: '50%', left: 0, bottom: 'unset', right: 'unset', transofrm: 'translate(0, -50%)' },
    4: { top: '50%', left: '50%', bottom: 'unset', right: 'unset', transofrm: 'translate(-50%, -50%)' },
    5: { top: '50%', left: 'unset', bottom: 'unset', right: 0, transofrm: 'translate(0, -50%)' },
    6: { top: 'unset', left: 0, bottom: 0, right: 'unset', transofrm: 'translate(0, 0)' },
    7: { top: 'unset', left: '50%', bottom: 0, right: 'unset', transofrm: 'translate(-50%, 0)' },
    8: { top: 'unset', left: 'unset', bottom: 0, right: 0, transofrm: 'translate(0, 0)' },
  },
  responsiveOptions: [
    { value: 'lg', label: 'Large' },
    { value: 'sm', label: 'Small' },
  ],
  animationOptions: [
    { value: 'none', label: 'None' },
    { value: 'slideleft', label: 'Slide left' },
    { value: 'slideright', label: 'Slide right' },
    { value: 'pop', label: 'Pop' },
    { value: 'fade', label: 'Fade' },
    { value: 'flip', label: 'Flip' },
    { value: 'turn', label: 'Turn' },
    { value: 'flow', label: 'Flow' },
    { value: 'slideup', label: 'Slide up' },
    { value: 'slidedown', label: 'Slide down' },
  ],
  borderOptions: [
    { value: 'none', label: 'None' },
    { value: 'dotted', label: 'Dotted' },
    { value: 'dashed', label: 'Dashed' },
    { value: 'solid', label: 'Solid' },
    { value: 'double', label: 'Double' },
    { value: 'groove', label: 'Groove' },
    { value: 'ridge', label: 'Ridge' },
    { value: 'inset', label: 'Inset' },
    { value: 'outset', label: 'Outset' },
  ],
  fontWeightOptions: [
    { value: 'normal', label: 'Normal' },
    { value: 'bold', label: 'Bold' },
  ],
  alignmentOptions: [
    { value: 'left', label: 'Left' },
    { value: 'center', label: 'Center' },
    { value: 'right', label: 'Right' },
  ],
  textDecorationOptions: [
    { value: 'none', label: 'None' },
    { value: 'underline', label: 'Underline' },
    { value: 'overline', label: 'Overline' },
    { value: 'underline overline', label: 'Underline & Overline' },
    { value: 'line-through', label: 'Strikethrough' },
  ],
  clickActionOptions: [
    { value: 'redirect', label: 'Redirect URL' },
    { value: 'clipboard', label: 'Copy To Clipboard' },
    { value: 'close', label: 'Close Popup' },
    { value: 'spin', label: 'Spin Popup' },
    { value: 'popup', label: 'Open Other Popup' },
    { value: 'push', label: 'Push' },
  ],
  afterSubmissionActionOptions: [
    { value: 'message', label: 'Show Succes Message' },
    { value: 'redirect', label: 'Redirect' },
    { value: 'close', label: 'Close Popup' },
    { value: 'spin', label: 'Spin Popup' },
    { value: 'popup', label: 'Open Other Popup' },
  ],
  cookieExpirationDaysOptions: [
    { value: '1', label: '1 day' },
    { value: '7', label: '7 days' },
    { value: '30', label: '30 days' },
    { value: '90', label: '90 days' },
    { value: '365', label: '365 days' },
  ],
  countDownOptions: [
    { value: 'countdown', label: 'Count Down TO Date' },
    { value: 'timer', label: 'Repetitive Timer' },
  ],
  angleOptions: [
    { value: '0', label: '0' },
    { value: '45', label: '45' },
    { value: '90', label: '90' },
    { value: '135', label: '135' },
    { value: '180', label: '180' },
    { value: '225', label: '225' },
    { value: '270', label: '270' },
    { value: '315', label: '315' },
  ],
  facebookPageLikeBehaviorOptions: [
    { value: 'nothing', label: 'Do nothing' },
  ],
  currencyOptions: [
    { value: 'usd', label: 'U.S. Dollar - USD' },
    { value: 'aud', label: 'Australian Dollar - AUD' },
    { value: 'brl', label: 'Brazilian Real - BRL' },
    { value: 'cad', label: 'Canadian Dollar - CAD' },
    { value: 'cny', label: 'Chinese Yuan - CNY' },
    { value: 'eur', label: 'Euro - EUR' },
    { value: 'gbp', label: 'British Pound - GBP' },
  ],
  paypalActionTypes: [
    { value: 'addToCart', label: 'Add To Cart' },
    { value: 'buyNow', label: 'Buy Now' },
    { value: 'donation', label: 'Donation' },
    { value: 'subscription', label: 'Subscription' },
  ],
  buttonSizeOptions: [
    { value: '16px', label: '16' },
    { value: '32px', label: '32' },
    { value: '48px', label: '48' },
    { value: '64px', label: '64' },
  ],
  mapTypeOptions: [
    { value: 'roadmap', label: 'Roadmap' },
    { value: 'satellite', label: 'Satellite' },
    { value: 'hybrid', label: 'Hybrid' },
    { value: 'terrain', label: 'Terrain' },
  ],
  zoomOptions: [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
  ],
  mapConfigOptions: [
    { value: 'markers', label: 'Markers' },
    { value: 'directions', label: 'Directions', hint: 'lorem ipsum dolor' },
    { value: 'polygon', label: 'Polygon' },
  ],
  ruleOptions: [
    { value: 'onload', label: 'On Load' },
    { value: 'onscroll', label: 'On Scroll' },
    { value: 'onclick', label: 'On Click' },
    { value: 'exit', label: 'Exit-Intent' },
    { value: 'trigger', label: 'Trigger Confoguration' },
    { value: 'targeting', label: 'Page Targeting' },
    { value: 'time', label: 'Time on page' },
    { value: 'location', label: 'Physical Location' }
  ]
};

export const dateRanges = {
  yesterday: {
    name: 'Yesterday',
    startDate: moment().utc().subtract(1, 'days').startOf('day'),
    endDate: moment().utc().subtract(1, 'days').endOf('day'),
  },
  last_7_days: {
    name: 'Last 7 Days',
    startDate: moment().utc().subtract(5, 'day').subtract(1, 'day').startOf('day'),
    endDate: moment().utc().endOf('day'),
  },
  lifetime: {
    name: 'The Very Beginning',
    startDate: moment([2016]),
    endDate: moment().utc().endOf('day'),
  },
};

export const PAGESDATA = {
  campaigns: {
    popup: {
      types: {
        all: 'All types',
        video: 'Video',
        iframe: 'Iframe',
        social: 'Social',
        subscription: 'Subscription',
        contactForm: 'Contact Form',
        ageRestriction: 'Age Restriction',
        countdown: 'Countdown',
        mailchimp: 'Mailchimp',
        spinner: 'Spinner',
        facebookpage: 'Facebook Page',
        googlemap: 'Google Map',
        paypal: 'Paypal',
        pushNotification: 'Push Notification',
      },
    },
  },
  audience: {
    contacts: {
      limit: 10,
    },
  },
};

export const URLHISTORY = {
  start : window.location.pathname,
}

export const ACTIVATION = {
  email: new URLSearchParams(window.location.search).get('activationToken'),
  registration: new URLSearchParams(window.location.search).get('activateUser'),
  reset: new URLSearchParams(window.location.search).get('resetPassword'),
}

export const CLIENT = {
  id: 'popup_maker_rock',
  secret: '3b6fb9033a8302fc168ca0199caaba142dbc5530'
}
