import React from 'react';

// Load Vendors
import classNames from 'classnames';

// Load Icons
import * as Icons from 'shared/components/Icons';

const Button = ({
  type = 'button',
  apperance = 'primary',
  iconPlacement = 'left',
  onClick = () => {},
  className,
  icon,
  block,
  disabled,
  size,
  children,
}) => {
  const Icon = (icon && Icons[icon]) ?? null;
  const showIconOnLeft = Icon && iconPlacement === 'left';
  const showIconOnRight = Icon && iconPlacement === 'right';

  const classes = classNames(
    'btn',
    `btn-${apperance}`,
    {
      [`btn-${size}`]: size,
      'btn-icon': !!icon,
      'btn-only-icon': !!icon && !children,
      'btn-block': block,
      'btn-reverse': iconPlacement === 'right',
    },
    className,
  );
  return (
    <button type={type} disabled={disabled} className={classes} onClick={onClick}>
      {showIconOnLeft && <Icon className='icon' />}
      <span>{children}</span>
      {showIconOnRight && <Icon className='icon' />}
    </button>
  );
};

export default Button;
