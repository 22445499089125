const _getErrorArrays = (error) => {
  if (!error['Validation Failed']) return null;
  const errors = error['Validation Failed'];
  const messages = Object.values(errors);
  if (!messages) return null;
  let result = '';
  messages.map((item) => (result += `${item} \n`));
  return result;
};

// export const getError = (err) => {
//   if (err?.response?.data) {
//     const msg = err.response.data.message;
//     const error = _getErrorArrays(err.response.data);
//     if (error) return error;
//     if (msg) return msg;
//   } else if (err?.response?.statusText) {
//     return err?.response?.statusText;
//   } else if (err?.data?.message) {
//     return err.data.message;
//   } else if (err?.message) {
//     return err?.message;
//   } else {
//     return err;
//   }
// };

export const getError = (err) => {
  let msg;
  if (err?.response?.data) {
    if (err.response.data.message) msg = err.response.data.message;
    else msg = err.response.data.error_description;
    const error = _getErrorArrays(err.response.data);
    if (error) return error;
    if (msg) return msg;
  } else if (err?.response?.statusText) {
    return err?.response?.statusText;
  } else if (err?.data?.message) {
    return err.data.message;
  } else if (err?.message) {
    return err?.message;
  } else {
    return err;
  }
};

export const extractJSON = (str) => {
  let firstOpen, firstClose, candidate;
  firstOpen = str.indexOf('{', firstOpen + 1);
  do {
    firstClose = str.lastIndexOf('}');
    // console.log('firstOpen: ' + firstOpen, 'firstClose: ' + firstClose);
    if (firstClose <= firstOpen) {
      return null;
    }
    do {
      candidate = str.substring(firstOpen, firstClose + 1);
      // console.log('candidate: ' + candidate);
      try {
        let res = JSON.parse(candidate);
        // console.log('...found');
        return [res, firstOpen, firstClose + 1];
      } catch (e) {
        // console.log('...failed');
      }
      firstClose = str.substr(0, firstClose).lastIndexOf('}');
    } while (firstClose > firstOpen);
    firstOpen = str.indexOf('{', firstOpen + 1);
  } while (firstOpen != -1);
};
