import { createReducer } from 'utils/reduxHelpers';
import { AUTH } from 'redux/actionTypes';

const initialState = null;

const handlers = {
  [AUTH.SET_STATE]: (state, action) => action.payload,
  [AUTH.LOGIN]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [AUTH.LOGOUT]: (state, action) => null,
};

export default createReducer(initialState, handlers);
