import React, { Suspense } from 'react';

// Load Vendors
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { snackbarSettings } from './configs';
import 'react-dates/initialize';

// Load Store
import store from './redux/store';

// Load Styles
import 'scss/app.scss';

// Load App
import App from './app';

render(
  <Provider store={store}>
    <SnackbarProvider {...snackbarSettings}>
      <Suspense fallback=''>
        <Router>
          <App />
        </Router>
      </Suspense>
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root'),
);
