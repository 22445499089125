const generic = {
  prod: {
    url_protocol: 'https',
    api_domain: 'pmback.popupmaker.com/api',
    analytics_api_domain: 'localhost/popup-maker-stats/api',
    prefix: 'v1',
  },
  stage: {
    url_protocol: 'https',
    api_domain: 'pmback.popupmaker.com/api',
    analytics_api_domain: 'localhost/popup-maker-stats/api',
    prefix: 'v1',
  },
  dev: {
    // url_protocol: 'https',
    url_protocol: 'http',
    // api_domain: 'dev.popupmaker.com/api',
    api_domain: 'localhost/popup-maker-api/api',
    analytics_api_domain: 'localhost/popup-maker-stats/api',
    prefix: 'v1',
  },
  local: {
    url_protocol: 'http',
    api_domain: 'localhost',
    analytics_api_domain: 'localhost/popup-maker-stats/api',
    prefix: 'v1',
  },
};

export const getENV = () => {
  let env = process.env.REACT_APP_API_URL_ENV || process.env.NODE_ENV;
  env = env === 'development' ? 'dev' : env;
  return env;
};

const main = generic[getENV() || 'dev'];

export const HOST = {
  API: {
    URL: `${main.url_protocol}://${main.api_domain}`,
    ANALYTICS_URL: `${main.url_protocol}://${main.analytics_api_domain}`,
    API_PREFIX: main.prefix,
    AUTH_HEADER: 'Authorization',
    AUTH_PREFIX: 'Bearer',
  },
};
