import React from 'react';

// Load Hooks
import { useLocation } from 'react-router-dom';
import useAccount from '../../../app/routes/Account/hooks/useAccount';

// Load Components
import Header from './Header';

// Load Consts
import { REGS } from 'configs';

const MainLayout = ({ children }) => {
  const { pathname } = useLocation();
  // useAccount();
  if (REGS.mainLayoutExceptions.test(pathname)) return children;

  return (
    <div className='min-vh-100 d-flex flex-column'>
      <Header className='position-sticky t-0' />
      <main className='flex-fill py-8'>{children}</main>
    </div>
  );
};

export default MainLayout;
