import React, { useState, useRef } from 'react';

// Load Hooks
import { useSnackbar } from 'notistack';

// Load Vendors
import classNames from 'classnames';
import { copyToClipboard } from 'utils/appHelpers';

// Load Icons
import * as Icons from 'shared/components/Icons';

const Input = ({
  icon,
  iconPlacement = 'left',
  label,
  className,
  feedback,
  isValid,
  isInvalid,
  block,
  selectOnFocus,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const input = useRef();
  const [showContent, setContentShow] = useState(false);

  const { value, type = 'text', maxLength, disabled, readOnly, onFocus } = props;

  const handleFocus = (e) => {
    if (selectOnFocus && !readOnly) input.current.select();
    input.current.select();
    onFocus?.(e);
  };

  const Icon =
    type === 'search'
      ? Icons['Search']
      : type === 'password'
      ? Icons[showContent ? 'EyeClose' : 'Eye']
      : icon && Icons[icon];
  const showIconOnLeft = type === 'search' || (icon && iconPlacement === 'left');
  const showIconOnRight = type === 'password' || (icon && iconPlacement === 'right');
  const showMaxLength = maxLength && typeof value === 'string';

  const isActionButton = type === 'password' || icon === 'Duplicate';

  const handleCopy = () => {
    copyToClipboard(value);
    enqueueSnackbar('Successfully Copied!', { variant: 'success' });
  };

  const handleIconClick = (e) => {
    isActionButton && e.stopPropagation();
    if (type === 'password') setContentShow(!showContent);
    if (icon === 'Duplicate') handleCopy();
  };

  const setIconClasses = () => {
    if (showIconOnLeft) {
      return 'icon mr-2';
    } else {
      return `icon ml-2${isActionButton ? ' cursor-pointer' : ''}`;
    }
  };

  const containerClasses = classNames(
    'input-container w-100',
    {
      'mxw-md-360-px': !block,
    },
    className,
  );
  const holderClasses = classNames('input', {
    [`is-valid`]: isValid,
    [`is-invalid`]: isInvalid,
    disabled: disabled,
  });
  const inputClasses = classNames({ 'cursor-default': readOnly });
  const feedbackClasses = classNames('input-feedback', { 'mt-1': feedback || showMaxLength });
  return (
    <div className={containerClasses}>
      {label && <label className='text-sm fw-semibold mb-1'>{label}</label>}
      <div className={holderClasses} onClick={handleFocus} role='presentation'>
        {showIconOnLeft && <Icon width='20' height='20' className={setIconClasses()} />}
        <input ref={input} {...props} className={inputClasses} type={showContent ? 'text' : type} />
        {showIconOnRight && (
          <Icon width='20' height='20' className={setIconClasses()} onClick={handleIconClick} />
        )}
      </div>
      <div className={feedbackClasses}>
        <span className='flex-fill'>{feedback}</span>
        {showMaxLength && (
          <span className='flex-fill text-right pl-4'>
            {value.length}/{maxLength}
          </span>
        )}
      </div>
    </div>
  );
};

export default Input;
