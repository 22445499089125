import { createReducer } from 'utils/reduxHelpers';
import { AUDIENCE } from 'redux/actionTypes';

const initialState = {
  contacts: [],
};

const handlers = {
  [AUDIENCE.GET_DATA]: (state, action) => ({
    ...state,
    contacts: [...action.payload],
  }),
  [AUDIENCE.DELETE_DATA]: (state, action) => {
    const contactId = action.payload.ids;
    console.log('contactId', contactId);
    const contacts = Array.isArray(contactId)
      ? state.contacts.filter((contactObj) => !contactId.includes(contactObj.id))
      : state.contacts.filter((contactObj) => contactObj.id !== contactId);
    // const contacts = state.contacts.filter((contactObj) => contactObj.id !== contactId);
    return {
      ...state,
      contacts,
    };
  },
};

export default createReducer(initialState, handlers);
