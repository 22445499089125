export default class TokenService {
  static tokenConfig = {
    access: 'auth',
    refresh: 'refresh_token',
  };
  // storing the token
  static save = (token, type = 'access') => {
    localStorage.setItem(this.tokenConfig[type], token);
  };

  static get = (type = 'access') => {
    return localStorage.getItem(this.tokenConfig[type]);
  };

  static remove = (type = 'access') => {
    localStorage.removeItem(this.tokenConfig[type]);
  };
}
