import React from 'react';

// Load Components
import AuthSlider from '../sliders/AuthSlider';
import { Link } from 'react-router-dom';

// Load Icons
import logoWmBlack from 'assets/images/logos/LogoWmBlack.svg';

const AuthLayout = ({ children }) => (
  <div className='vh-100 d-flex'>
    <div className='d-flex flex-column align-items-center w-100 mxw-md-440-px mxw-lg-520-px overflow-auto no-scroll-bar px-4'>
      <main className='flex-fill w-100 mxw-360-px pt-10 pb-16 pb-sm-18'>
        <div className='mb-10'>
          <Link to='/login' className='d-block w-84-px'>
            <img src={logoWmBlack} alt='Logo' />
          </Link>
        </div>
        {children}
      </main>
      <footer className='w-100 mxw-xl-520-px px-4 px-md-10 py-6 px-lg-20'>
        <span className='text-sm'>
          &copy; {new Date().getFullYear()} Popupmaker. All rights reserved
        </span>
      </footer>
    </div>
    <div className='flex-fill d-none d-md-flex overflow-hidden'>
      <AuthSlider />
    </div>
  </div>
);

export default AuthLayout;
