import React from 'react';

// Load Components
import { NavLink } from 'react-router-dom';

// Load Icons
import * as Icons from 'shared/components/Icons';

const Button = ({ icon, to, children }) => {
  const Icon = (icon && Icons[icon]) ?? null;

  const content = (
    <>
      {Icon && <Icon width={20} height={20} className='mr-2 fill-gray-800' />}
      {children}
    </>
  );

  return to ? (
    <NavLink to={to} className='app-dropdown-item-button'>
      {content}
    </NavLink>
  ) : (
    <div className='app-dropdown-item-button'>{content}</div>
  );
};

export default Button;
