import { useEffect } from 'react';

export const useOutsideClick = (ref, handler, prevent) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target) || prevent) {
        return;
      }
      handler(event);
    };

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
    //eslint-disable-next-line
  }, [ref, handler]);
};
