import React from 'react';

// Load Components
import AppLayout from 'shared/components/layout/Applayout';
import Router from './components/Router';

const App = () => {
  return (
    <AppLayout>
      <Router />
    </AppLayout>
  );
};

export default App;
