import { createReducer } from 'utils/reduxHelpers';
import { ACCOUNT } from 'redux/actionTypes';

const initialState = null;

const handlers = {
  [ACCOUNT.GET_DATA]: (state, action) => ({ ...state, ...action.payload }),
  [ACCOUNT.SET_DATA]: (state, action) => ({ ...state, ...action.payload }),
  [ACCOUNT.DELETE_DATA]: (state, action) => action.payload,
};

export default createReducer(initialState, handlers);
