import React from 'react';

// Load Icons
import { LogoWmBlack } from 'shared/components/Icons';

const Authheader = () => (
  <div className='d-flex align-items-center justify-content-center h-100'>
    <LogoWmBlack width={132} height={28} />
  </div>
);

export default Authheader;
