import React, { cloneElement, useState, useRef } from 'react';

// Load Hooks
import { useOutsideClick } from 'shared/hooks';

// Load Components
import Toggle from './components/Toggle';
import Header from './components/Header';
import Item from './components/Item';
import Footer from './components/Footer';

// Load Vendors
import classNames from 'classnames';
import { getChildrenArray } from 'utils/appHelpers';

const Dropdown = ({
  size,
  opens = 'right',
  className,
  bodyClass,
  block,
  isCustom,
  closeOnItemClick = true,
  children,
}) => {
  const dropdown = useRef();
  const [open, setOpen] = useState(false);

  const components = getChildrenArray(children);

  const ToggleComponent = components.find(({ type }) => type === Toggle);
  const HeaderComponent = components.find(({ type }) => type === Header);
  const FooterComponent = components.find(({ type }) => type === Footer);
  const EnhancedItems = components
    .filter(({ type }) => type === Item)
    .map((Item, i) =>
      cloneElement(Item, {
        ...Item.props,
        key: i,
        onClick: () => {
          const { type, onClick } = Item.props;
          if ((type === 'button' || type === 'notification') && closeOnItemClick) setOpen(!open);
          onClick?.();
        },
      }),
    );

  const CustomChildren = components.filter(
    ({ type }) => type !== Toggle && type !== Header && type !== Footer,
  );

  useOutsideClick(dropdown, () => setOpen(false));

  const dropdownClasses = classNames(
    'app-dropdown',
    `opens-${opens}`,
    {
      'd-inline-flex': !block,
      [`app-dropdown-${size}`]: size && !isCustom,
      open,
    },
    className,
  );
  const bodyClasses = classNames('app-dropdown-body', bodyClass);

  return (
    <div ref={dropdown} className={dropdownClasses}>
      {!!ToggleComponent &&
        cloneElement(ToggleComponent, {
          ...ToggleComponent.props,
          onClick: () => setOpen(!open),
        })}
      <div className={bodyClasses}>
        {!!HeaderComponent && cloneElement(HeaderComponent, HeaderComponent.props)}
        {isCustom ? CustomChildren : <div className='app-dropdown-body-inner'>{EnhancedItems}</div>}
        {!!FooterComponent &&
          cloneElement(FooterComponent, {
            ...FooterComponent.props,
            onClick: () => setOpen(!open),
          })}
      </div>
    </div>
  );
};

Dropdown.Toggle = Toggle;
Dropdown.Header = Header;
Dropdown.Item = Item;
Dropdown.Footer = Footer;

export default Dropdown;
