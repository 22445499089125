import React, { useEffect, useState } from 'react';

// Load Components
import Slider from 'react-slick';
import Button from 'shared/components/buttons/Button';
import { Link } from 'react-router-dom';

// Load Illustrations
import increaseYourSales from 'assets/images/illustrations/IncreaseYourSales.png';
import generateLeads from 'assets/images/illustrations/GenerateLeads.png';
import connectVisitors from 'assets/images/illustrations/ConnectVisitors.png';

const SlideArrow = ({ type, className, onClick }) => (
  <Button
    apperance='text'
    icon={type === 'next' ? 'ArrowForward' : 'ArrowBack'}
    className={className}
    onClick={onClick}
  />
);

const settings = {
  dots: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: 'auth-slider',
  prevArrow: <SlideArrow type='prev' className='slick-arrow slick-prev' />,
  nextArrow: <SlideArrow type='next' className='slick-arrow slick-next' />,
};

const items = [
  {
    title: 'Increase your sales',
    text: 'Sell more to your website visitors and make the most of your traffic.',
    linkText: 'Drive More Revenue',
    url: '/increase-sales', // TODO: add correct url from website
    imgUrl: increaseYourSales,
    className: 'bg-linen',
  },
  {
    title: 'Generate Leads',
    text: 'Build a solid e-mail list with website subscription popup campaigns.',
    linkText: 'Grow Your Email List',
    url: '/generate-leads', // TODO: add correct url from website
    imgUrl: generateLeads,
    className: 'bg-aqua-haze',
  },
  {
    title: 'Connect Visitors',
    text: 'Turn your website to a digital assistant who communicates directly with your visitors.',
    linkText: 'Get In Touch With Visitors',
    url: '/connect-visitors', // TODO: add correct url from website
    imgUrl: connectVisitors,
    className: 'bg-hawkes-blue',
  },
];

const AuthSlider = () => {
  const [ready, setReady] = useState(true);

  useEffect(() => {
    setReady(true);
  }, []);

  if (!ready) return null;
  return (
    <Slider {...settings}>
      {items.map(({ title, text, linkText, url, imgUrl, className }) => {
        return (
          <div key={title} className={`vh-100 pt-60 ${className}`}>
            <div className='d-flex flex-column align-items-center text-center mxw-328-px mxw-lg-384-px mx-auto'>
              <img src={imgUrl} alt={title} />
              <h6 className='fw-bold mt-4 mb-2'>{title}</h6>
              <p className='text-lg text-secondary mb-3'>{text}</p>
              <a href={url} className='btn btn-secondary' rel='noopener'>
                {linkText}
              </a>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default AuthSlider;
