import React from 'react';
import SnackbarCloseButton from 'shared/components/buttons/SnackbarCloseButton';
import { CheckmarkOutlined, Info, ArrowBack, ArrowForward } from 'shared/components/Icons';

export const snackbarSettings = {
  maxSnack: 5,
  autoHideDuration: 3000,
  preventDuplicate: true,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  className: 'snackbar',
  action: (id) => <SnackbarCloseButton k={id} />,
  classes: {
    variantSuccess: 'snackbar-success',
    variantInfo: 'snackbar-info',
    variantWarning: 'snackbar-warning',
    variantError: 'snackbar-danger',
  },
  iconVariant: {
    success: <CheckmarkOutlined className='fill-success mr-2' />,
    info: <Info className='fill-info mr-2' />,
    warning: <Info className='fill-warning mr-2' />,
    error: <Info className='fill-danger mr-2' />,
  },
  // dense: true,
};

export const modalSettings = {
  title: 'Confirmation Dialog',
  hideTitle: false,
  hideHeader: false,
  hideFooter: false,
  hideCancelBtn: false,
  hideSubmitBtn: false,
  cancelBtnText: 'Cancel',
  cancelBtnClass: 'secondary',
  cancelBtnIcon: null,
  cancelBtnDisabled: false,
  submitBtnText: 'Submit',
  submitBtnClass: 'primary',
  submitBtnIcon: null,
  submitBtnDisabled: false,
  shouldCloseOnOverlayClick: true,
};

export const confirmModalSettings = {
  title: 'Confirmation Alert',
  cancelBtnText: 'Cancel',
  submitBtnText: 'Confirm',
};

export const paginationSettings = {
  previousLabel: <ArrowBack />,
  nextLabel: <ArrowForward />,
  breakLabel: '...',
  activeClassName: 'active',
  marginPagesDisplayed: 1,
  pageRangeDisplayed: 3,
};

export const colorPickerSettings = {
  presetColors: [
    '#EF4444',
    '#F97316',
    '#FACC15',
    '#4ADE80',
    '#2DD4BF',
    '#3B82F6',
    '#6366F1',
    '#EC4899',
    '#F43F5E',
    '#D946EF',
    '#8B5CF6',
    '#0EA5E9',
    '#10B981',
    '#84CC16',
  ],
};
