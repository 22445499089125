// Load vendors
import axios from 'axios';
// import i18n from 'i18next';

// Load configs
import { HOST } from 'configs/host';

// Load Services
import TokenService from 'services/TokenService';

// Load Consts
import { CLIENT } from 'configs';

// Set config defaults when creating the instance
export const Api = axios.create({
  baseURL: `${HOST.API.URL}/${HOST.API.API_PREFIX}`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: { common: {} },
  data: {},
});

export const $Api = axios.create({
  baseURL: `${HOST.API.URL}/${HOST.API.API_PREFIX}`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: { common: {} },
  data: {},
});

Api.interceptors.request.use(
  (config) => {
    if (config.url === '/token') {
      config.data.client_secret = CLIENT.secret;
      config.data.client_id = CLIENT.id;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

$Api.interceptors.request.use(
  (config) => {
    const token = TokenService.get();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

$Api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== '/token/' && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await $Api.post('/token/refresh', {
            refresh_token: TokenService.get('refresh'),
            grant_type: 'refresh_token',
            client_secret: CLIENT.secret,
            client_id: CLIENT.id,
          });
          const { access_token, refresh_token } = rs.data;
          TokenService.save(access_token);
          TokenService.save(refresh_token, 'refresh_token');
          return $Api(originalConfig);
        } catch (_error) {
          TokenService.remove();
          TokenService.remove('refresh');
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  },
);

export const AnalyticsApi = axios.create({
  baseURL: `${HOST.API.ANALYTICS_URL}/${HOST.API.API_PREFIX}`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: { common: {} },
  data: {},
});

export const setHeaderAuthToken = (token, api = Api) => {
  api.defaults.headers.common['Authorization'] = `${HOST.API.AUTH_PREFIX} ${token}`;
};
