import React, { useMemo, useRef } from 'react';

// Load Components
import ImagePlaceholder from './ImagePlaceholder';

// Load Vendors
import classNames from 'classnames';
import { extractFirstLetters } from 'utils/appHelpers';

const avatarColors = [
  'carnation',
  'astral',
  'fuel-yellow',
  'jordy-blue',
  'valencia',
  'celery',
  'mandy',
  'paradiso',
];

const Avatar = ({ size, imageURL, placeholder, className }) => {
  const text = useRef();
  const image = useRef();
  const placeholderImage = useRef();

  const randomIndex = useMemo(() => {
    return Math.floor(Math.random() * avatarColors.length);
  }, [placeholder]);
  const classes = classNames(
    'avatar',
    {
      [`avatar-${size}`]: size,
    },
    `bg-${avatarColors[randomIndex]}`,
    className,
  );

  const onLoad = () => {
    image.current?.classList.remove('d-none');
    text.current?.classList.add('d-none');
  };

  const onError = () => {
    placeholderImage.current?.classList.remove('d-none');
  };

  const placeholderText = extractFirstLetters(placeholder);
  return (
    <div className={classes}>
      <span ref={text} className='text'>
        {placeholderText}
      </span>
      {imageURL && (
        <>
          <img
            ref={image}
            src={imageURL}
            onLoad={onLoad}
            onError={onError}
            alt='Avatar'
            className='d-none'
          />
          <ImagePlaceholder size={size} refPath={placeholderImage} className='placeholder d-none' />
        </>
      )}
    </div>
  );
};

export default Avatar;
