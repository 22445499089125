import React, { useRef } from 'react';

// Load Vendors
import classNames from 'classnames';

const Checkbox = ({
  label,
  name,
  checked,
  indeterminated,
  disabled,
  className,
  onChange = () => {},
}) => {
  const input = useRef();

  return (
    <div
      className={classNames('d-flex align-items-center cursor-pointer', className)}
      onClick={(e) => e.stopPropagation()}
      role='presentation'
    >
      <label
        className={classNames('checkbox', { checked, indeterminated, disabled })}
        tabIndex='-1'
      >
        <input
          ref={input}
          name={name}
          type='checkbox'
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <span className='area' />
      </label>
      {label && (
        <span
          className='flex-fill text-sm fw-semibold text-nowrap pl-2 py-1'
          onClick={() => input.current?.click()}
          role='presentation'
        >
          {label}
        </span>
      )}
    </div>
  );
};

export default Checkbox;
