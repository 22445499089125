import React from 'react';

// Load Vendors
import classNames from 'classnames';

const HyperLink = ({ variant, size, disabled, block, className, onClick = () => {}, children }) => {
  const classes = classNames(
    'hyperlink',
    {
      [`hyperlink-${variant}`]: variant,
      [`text-${size}`]: size,
      'd-block': block,
      'd-inline-block': !block,
      disabled,
    },
    className,
  );

  return (
    <span className={classes} disabled={disabled} onClick={onClick} role='presentation'>
      {children}
    </span>
  );
};

export default HyperLink;
