import React from 'react';

// Load Hooks
import { useLocation } from 'react-router-dom';

// Load Components
import { Link } from 'react-router-dom';

// Load Icons
import { Close, Logo } from 'shared/components/Icons';

const StructuralHeader = () => {
  const { pathname } = useLocation();
  const redirectPath = /autoresponder/.test(pathname) ? 'autoresponder' : 'email-marketing';

  return (
    <div className='d-flex align-items-center justify-content-between h-100'>
      <Link to={`/campaigns/${redirectPath}`}>
        <Logo />
      </Link>
      <div>
        <Link to={`/${redirectPath}/templates`} className='btn btn-text'>
          Change Template
        </Link>
        <Link to={`/campaigns/${redirectPath}/preview`} className='btn btn-primary ml-3'>
          Continue
        </Link>
        <Link
          to={`/campaigns/${redirectPath}`}
          className='btn btn-text btn-icon btn-only-icon ml-3'
        >
          <Close className='icon' />
        </Link>
      </div>
    </div>
  );
};

export default StructuralHeader;
