import React from 'react';

// Load Components
import Button from 'shared/components/buttons/Button';

// Load Icons
import { LogoWmBlack } from 'shared/components/Icons';

const Onboardingheader = () => (
  <div className='d-flex align-items-center justify-content-between h-100'>
    <LogoWmBlack width={132} height={28} />
    <Button apperance='secondary'>Logout</Button>
  </div>
);

export default Onboardingheader;
