import React from 'react';

// Load Components
import { Link } from 'react-router-dom';

// Load Vendors
import classNames from 'classnames';

const NotificationCard = ({ size, title, date, text, id, read, active, className }) => {
  const classes = classNames(
    'notification-card rounded-2 cursor-pointer',
    {
      [`notification-card-${size}`]: size,
      'notification-card-read': read,
      'notification-card-active': active,
    },
    className,
  );

  return (
    <Link to={`/notifications/${id}`} className={classes}>
      <p className='title'>{title}</p>
      <p className='date'>{date}</p>
    </Link>
  );
};

export default NotificationCard;
