import { createReducer } from 'utils/reduxHelpers';
import { CAMPAIGNS } from 'redux/actionTypes';

const initialState = [];

const handlers = {
  [CAMPAIGNS.SET_DATA]: (state, action) => action.payload,
  [CAMPAIGNS.GET_POPUPS]: (state, action) => action.payload,
  [CAMPAIGNS.GET_MORE_POPUPS]: (state, action) => [...state, ...action.payload],
  [CAMPAIGNS.GET_FILTERED_POPUPS]: (state, action) => action.payload,
  [CAMPAIGNS.DELETE_POPUP]: (state, action) => {
    const popupId = action.payload.id;

    return state.filter(function (popupObj) {
      //create new array from campaignStore
      return popupObj.id !== popupId;
    });
  },
  [CAMPAIGNS.ENABLE_POPUP]: (state, action) => {
    const { id: popupId, isEnable } = action.payload;
    const index = state.findIndex(function (popupObj) {
      //create new array from campaignStore
      return popupObj.id === popupId;
    });
    state[index].enable = isEnable;

    return [...state];
  },
};

export default createReducer(initialState, handlers);
