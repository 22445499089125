import React from 'react';

// Load Vendors
import classNames from 'classnames';

// Load illustrations
import placeholderSm from 'assets/images/illustrations/PlaceholderSM.svg';
import placeholderMd from 'assets/images/illustrations/PlaceholderMD.svg';
import placeholderLg from 'assets/images/illustrations/PlaceholderLG.svg';

const imageBySizeMap = {
  sm: placeholderSm,
  md: placeholderMd,
  lg: placeholderLg,
  xl: placeholderLg,
};

const ImagePlaceholder = ({ size, refPath, className }) => {
  const classes = classNames(className);
  return (
    <img
      ref={refPath}
      src={imageBySizeMap[size] ?? imageBySizeMap.md}
      alt='Placeholder'
      className={classes}
    />
  );
};

export default ImagePlaceholder;
