import React, { useState } from 'react';

// Load Icons
import { Close, Logo, Pen } from 'shared/components/Icons';

// Load Components
import NavTabsHorizontal from 'shared/components/tabs/NavTabsHorizontal';
import Button from 'shared/components/buttons/Button';
import Input from 'shared/components/forms/Input';
import { Link } from 'react-router-dom';

// Load Consts
import { CONSTS } from 'configs';

const EditorHeader = () => {
  const [isEditingActive, setIsEditingActive] = useState(false);
  const [name, setName] = useState('Campaign Title');

  return (
    <div className='d-flex align-items-center justify-content-between h-100'>
      <div className='d-flex align-items-center'>
        <Link to='/campaigns/popups'>
          <Logo className='mr-4' />
        </Link>
        <NavTabsHorizontal items={CONSTS.headerNavigationEditor} size='lg' />
      </div>
      <div>
        {isEditingActive ? (
          <Input
            value={name}
            onChange={({ target }) => setName(target.value)}
            className='mw-160-px mxw-160-px mb-0'
            autoFocus
            icon='Checkmark'
            iconPlacement='right'
            onBlur={() => setIsEditingActive(false)}
          />
        ) : (
          <div className='d-flex align-items-center justify-content-center'>
            <span
              className='fw-semibold'
              onClick={() => setIsEditingActive(true)}
              role='presentation'
            >
              {name}
            </span>
            <Pen width='20' height='20' className='mw-20-px ml-2' autoFocus />
          </div>
        )}
      </div>
      <div>
        <Button apperance='text'>Share</Button>
        <Button apperance='secondary' className='ml-3'>
          Get Embed Code
        </Button>
        <Button apperance='primary' className='ml-3'>
          Save
        </Button>
        <Link to='/campaigns/popups' className='btn btn-text btn-icon btn-only-icon ml-3'>
          <Close className='icon' />
        </Link>
      </div>
    </div>
  );
};

export default EditorHeader;
