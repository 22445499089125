import update from 'lodash/update';
import cloneDeep from 'lodash/cloneDeep';

const _fieldDefaultStyles = {
  label: {
    initial: {
      display: 'block',
      color: '#000000',
    },
    hover: {},
    focus: {},
  },
  input: {
    initial: {
      width: '250px',
      height: '30px',
      fontSize: '14px',
      fontWeight: 'bold',
      borderStyle: 'none',
      borderColor: '#FFFFFF',
      borderWidth: '1px',
      borderRadius: '4px',
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: 0,
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
      marginLeft: 0,
    },
    hover: {},
    focus: {
      color: '#000000',
    },
  },
};

const _buttonDefaultStyles = {
  initial: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#D1D1D3',
    width: '120px',
    height: '30px',
    borderStyle: 'none',
    borderColor: '#FFFFFF',
    borderWidth: '1px',
    borderRadius: '4px',
    boxShadow: '0 0 0 0 #FFFFFF',
    textDecoration: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
  },
  hover: {
    backgroundColor: '#000000',
  },
  focus: {},
};

const contactFormFieldStyles = (field) => {
  let styles = cloneDeep(_fieldDefaultStyles);
  delete styles.input?.initial?.height;

  if (field === 'GDPRField') {
    styles = update(styles, 'input.initial', () => ({
      width: styles.input.initial.width,
      borderRadius: styles.input.initial.borderRadius,
      fontSize: styles.input.initial.fontSize,
      fontWeight: styles.input.initial.fontWeight,
    }));
  }

  return styles;
};

export const DEFAULTS = {
  popupLayoutDefaultConfigs: {
    overlay: {
      overlayEnabled: true,
      className: '',
    },
    frame: {
      backgroundEnabled: true,
      openAnimationName: 'slideright',
      closeAnimationName: 'none',
      openAnimationDuration: 700,
      closeAnimationDuration: 700,
      breakpoint: 'lg',
      maxWidth: '800px',
      minWidth: '700px',
      autoHeightEnabled: true,
    },
    closeButton: {
      enabled: true,
      label: 'Close button will appear after 8 Sec',
      showCountDownLabel: true,
      dismissOnEsc: true,
      dismissOnOverlayClick: true,
      dismissOnContentClick: false,
      autoClose: true,
      closeAfter: '12ms',
    },
    logo: {
      redirectURL1: 'https://popupmaker.com',
      redirectURL2: 'https://popupmaker.com',
      openNewTab: true,
    },
    floatingButton: {
      floatingButtonEnabled: true,
      type: 'basic',
      animationName: 'none',
      text: 'Sign Up!',
    },
  },
  popupLayoutDefaultStyles: {
    overlay: {
      backgroundColor: '#000000',
    },
    frame: {
      backgroundColor: '#FFFFFF',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      borderStyle: 'none',
      borderColor: '#FFFFFF',
      borderWidth: 0,
      borderRadius: 0,
      boxShadow: '0 0 0 0 #FFFFFF',
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: 0,
      position: 'fixed', // Not editable
      top: '50%',
      left: '50%',
      bottom: 'unset',
      right: 'unset',
      transofrm: 'translate(-50%, -50%)',
    },
    closeButton: {
      backgroundColor: '#000000', // NOTE: not used
      backgroundImage: '',
      backgroundSize: 'cover', // NOTE: not used
      backgroundRepeat: 'no-repeat', // NOTE: not used
      backgroundPosition: 'center', // NOTE: not used
      width: '20px',
      height: '20px',
      position: 'fixed',
      top: 0,
      left: 0,
      animationDelay: '0ms',
      fontSize: '14px',
      color: '#000000',
    },
    logo: {
      backgroundColor: '#000000', // NOTE: not used
      backgroundImage: '',
      backgroundSize: 'cover', // NOTE: not used
      backgroundRepeat: 'no-repeat', // NOTE: not used
      backgroundPosition: 'center', // NOTE: not used
    },
    floatingButton: {
      position: 'fixed', // Not editable
      top: '50%',
      left: 0,
      bottom: 'unset',
      right: 'unset',
      transofrm: 'translate(0, -50%)',
      backgroundColor: '#000000',
      borderStyle: 'none',
      borderColor: '#000000',
      borderWidth: 0,
      borderRadius: 0,
      boxShadow: '0 0 0 0 #000000',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#000000',
    },
  },
  popupElementDefaultConfigs: {
    block: {
      shadowEnabled: true,
    },
    spacer: {},
    divider: {},
    text: {},
    image: {},
    table: {},
    button: {
      text: 'Button',
      action: 'redirect',
      closeAfterClick: true,
      cookieExpirationDays: '365',
      domainWideCookie: false,
    },
    subscription: {
      general: {
        action: 'message',
        notificationEnabled: true,
        notificationType: 'immediate',
        cookieEnabled: true,
        cookieExpirationDays: '365',
        domainWideCookie: false,
        beforeSubscriptionActionName: 'myFunction',
      },
      button: {
        label: 'Subscribe',
        showInline: false,
      },
      email: {
        name: 'Email',
        label: 'Email',
        placeholder: 'Email',
        error_text: 'Please enter a valid email',
        required: true,
      },
      first_name: {
        name: 'First Name',
        label: 'First Name',
        placeholder: 'First Name',
        error_text: 'Field is required',
        required: true,
      },
      last_name: {
        name: 'Last Name',
        label: 'Last Name',
        placeholder: 'Last Name',
        error_text: 'Field is required',
        required: true,
      },
      phone: {
        name: 'Phone',
        label: 'Phone',
        placeholder: 'Phone',
        error_text: '',
        required: false,
        min_limit: true,
        max_limit: true,
        use_dial_codes: true,
      },
      number: {
        name: 'Number',
        label: 'Number',
        placeholder: 'Number',
        error_text: '',
        required: false,
        min_limit: true,
        max_limit: true,
      },
      text: {
        name: 'Text Field',
        label: 'Text Field',
        placeholder: 'Text Field',
        error_text: '',
        required: false,
      },
      textarea: {
        name: 'Text Area',
        label: 'Text Area',
        placeholder: 'Text Area',
        error_text: '',
        required: false,
        height: '300px',
      },
      dropdown: {
        name: 'Drop Down',
        isMultiSelect: false,
        options: [
          { value: '1', label: 'First Choice' },
          { value: '2', label: 'Second Choice' },
          { value: '3', label: 'Third Choice' },
        ],
      },
      radio: {
        name: 'Radio Button',
        error_text: '',
        required: false,
        inputColor: '#000000',
        labelColor: '#FFFFFF',
        options: [
          { value: '1', label: 'First Choice' },
          { value: '2', label: 'Second Choice' },
          { value: '3', label: 'Third Choice' },
        ],
      },
      checkbox: {
        name: 'Checkbox',
        error_text: '',
        required: false,
        inputColor: '#000000',
        labelColor: '#FFFFFF',
        options: [
          { value: '1', label: 'First Choice' },
          { value: '2', label: 'Second Choice' },
          { value: '3', label: 'Third Choice' },
        ],
      },
    },
    contactForm: {
      general: {
        action: 'message',
        notificationEnabled: true,
        notificationType: 'immediate',
        cookieEnabled: true,
        cookieExpirationDays: '365',
        domainWideCookie: false,
        afterCopyText: 'Copied!',
        closeCurrentPopup: true,
        redirectURL: 'https://text.com/',
        openInNewTab: true,
        beforeSubscriptionActionName: 'myFunction',
        message: 'You have successfully subscribed!',
      },
      emailField: {
        enabled: true,
        name: 'Email',
        label: 'Email',
        placeholder: 'Email',
        error_text: 'Please enter a valid email',
        required: true,
      },
      phoneField: {
        enabled: true,
        name: 'Phone',
        label: 'Phone',
        placeholder: 'Phone',
        error_text: 'Field is required',
        required: true,
      },
      nameField: {
        enabled: false,
        name: 'Name',
        label: 'Name',
        placeholder: 'Name',
        error_text: 'Field is required',
        required: true,
      },
      subjectField: {
        enabled: false,
        name: 'Subject',
        label: 'Subject',
        placeholder: 'Subject',
        error_text: 'Field is required',
        required: true,
      },
      messageField: {
        enabled: false,
        name: 'Message',
        label: 'Message',
        placeholder: 'Message',
        error_text: 'Field is required',
        required: true,
      },
      GDPRField: {
        enabled: true,
        name: 'GDPR',
        text: 'I consent to having Popup Maker collect',
        error_text: 'Field is required',
        required: true,
      },
      button: {},
    },
    iframe: {
      url: 'https://popupmaker.com',
    },
    video: {
      url: 'https://popupmaker.com',
      autoPlay: true,
    },
    spinner: {
      cookieEnabled: true,
      cookieExpirationDays: '365',
      domainWideCookie: false,
      slices: [
        { color: '#347A87', text: 'Sorry, try again' },
        { color: '#D24A3F', text: 'So close!' },
        { color: '#FFD1BA', text: '10% off' },
        { color: '#9AC85C', text: '5% off' },
        { color: '#C3DDFA', text: '45% off' },
      ],
    },
    restriction: {
      text: 'Are you 18+?',
      button1Text: 'No',
      button2Text: 'Yes',
      cookieExpirationDays: '365',
      domainWideCookie: false,
    },
    countDown: {
      action: 'countdown',
      days: 'Days',
      hours: 'Hours',
      minutes: 'Minutes',
      seconds: 'Seconds',
    },
    html: {
      putCode: false,
      autoHeight: false,
      code: `<h3 style="text-align:center;">Write any HTML tags in the Element Options <span style="color: #bd1716">(on the right panel)</span> to get the custom HTML you wish!</h3>`,
    },
    mailchimp: {
      general: {
        action: 'message',
        doubleOptInEnabled: false,
        redirectURL: 'https://text.com/',
        openInNewTab: true,
        message: 'You have successfully subscribed!',
      },
      emailField: {
        enabled: true,
        name: 'Email',
        label: 'Email',
        placeholder: 'Email',
        error_text: 'Please enter a valid email',
        required: true,
      },
      GDPRField: {
        enabled: true,
        name: 'GDPR',
        text: 'I consent to having Popup Maker collect',
        error_text: 'Field is required',
        required: true,
      },
      button: {
        label: 'Button',
      },
    },
    facebook: {
      url: 'https://www.facebook.com/',
      tabs: 'Timeline, Events, Message',
      pageLikeBehavior: 'nothing',
      useSmallHeader: true,
      hideCoverPhoto: true,
      adaptToPluginContainerWidth: true,
      showFriendsFaces: true,
    },
    paypal: {
      general: {
        currency: 'usd',
        amount: 5.99,
        receiverEmail: '',
        redirectURL: '',
        openInNewTab: true,
        action: 'addToCart',
        quantity: 1,
      },
      button: {
        label: 'Button',
        showIcon: true,
        showLogo: true,
        showLabel: true,
      },
    },
    social: {
      shareURL: '{CurrentPageURL}',
      facebook: true,
      twitter: true,
      email: true,
      linkedin: true,
      pinterest: true,
      whatsapp: true,
      stumbleUpon: true,
      line: true,
      reddit: true,
      tumblr: true,
      facebookLite: true,
      facebookPageURL: 'https://www.facebook.com/',
      label: true,
    },
    map: {
      apiKey: '',
      mapType: 'roadmap',
      zoom: 15,
      action: 'markers',
    },
  },
  popupElementDefaultStyles: {
    block: {
      backgroundColor: '#FFFFFF',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      borderStyle: 'none',
      borderColor: '#FFFFFF',
      borderWidth: 0,
      borderRadius: 0,
      boxShadow: '0 0 0 0 #FFFFFF',
    },
    spacer: {
      padding: '12px',
    },
    divider: {
      borderTopStyle: 'solid',
      marginLeft: 0,
      marginRight: 'auto',
      width: '120px',
      borderTopWidth: '4px',
      borderTopColor: '#FFFFFF',
    },
    text: {},
    image: {},
    table: {},
    button: {
      // fontSize: '14px',
      // fontWeight: 'bold',
      // color: '#000000',
      // textDecoration: 'none',
      // backgroundColor: '#000000',
      // // Pseudo states TODO: add pseudo states
      // hoverBackgroundColor: '#FFFFFF',
      // width: '120px',
      // height: '30px',
      // borderStyle: 'none',
      // borderColor: '#FFFFFF',
      // borderWidth: 0,
      // borderRadius: 0,
      // boxShadow: '0 0 0 0 #FFFFFF',
      // paddingTop: 0,
      // paddingBottom: 0,
      // paddingRight: 0,
      // paddingLeft: 0,
      // marginTop: 0,
      // marginBottom: 0,
      // marginRight: 0,
      // marginLeft: 0,
      ...cloneDeep(_buttonDefaultStyles),
    },
    subscription: {
      general: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000000',
      },
      field: cloneDeep(_fieldDefaultStyles),
      button: cloneDeep(_buttonDefaultStyles),
    },
    contactForm: {
      general: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000000',
      },
      emailField: contactFormFieldStyles('emailField'),
      phoneField: contactFormFieldStyles('phoneField'),
      nameField: contactFormFieldStyles('nameField'),
      subjectField: contactFormFieldStyles('subjectField'),
      messageField: contactFormFieldStyles('messageField'),
      GDPRField: contactFormFieldStyles('GDPRField'),
      button: cloneDeep(_buttonDefaultStyles),
    },
    iframe: {
      width: '300px',
      height: '200px',
    },
    video: {
      width: '300px',
      height: '150px',
    },
    spinner: {
      container: {
        width: `300px`,
        borderRadius: '100px',
        borderColor: '#FFFFFF',
      },
      pointer: {
        transform: 'rotate(135deg)',
        borderWidth: '3px',
        borderColor: '#FFFFFF',
        backgroundColor: '#FFFFFF',
      },
      spinner: {
        top: 0,
        left: 0,
        fontSize: '14px',
        fontWeight: 'normal',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderWidth: '1px',
        borderColor: '#FFFFFF',
      },
      logo: {
        backgroundImage: '',
        backgroundSize: 'cover', // NOTE: not used
        backgroundRepeat: 'no-repeat', // NOTE: not used
        backgroundPosition: 'center', // NOTE: not used
      },
    },
    restriction: {
      text: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000000',
      },
      button1: cloneDeep(_buttonDefaultStyles),
      button2: cloneDeep(_buttonDefaultStyles),
    },
    countDown: {},
    html: {},
    mailchimp: {
      general: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000000',
      },
      emailField: contactFormFieldStyles('emailField'),
      GDPRField: contactFormFieldStyles('GDPRField'),
      button: cloneDeep(_buttonDefaultStyles),
    },
    facebook: {
      width: '300px',
      height: '200px',
    },
    paypal: {
      general: {},
      button: cloneDeep(_buttonDefaultStyles),
    },
    social: {
      width: '36px',
      height: '36px',
    },
    map: {
      width: '12px',
      height: '4px',
      borderColor: '#FFFFFF',
      borderWidth: '4px',
      borderRadius: '4px',
    },
  },
};
