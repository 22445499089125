import { createReducer } from 'utils/reduxHelpers';
import { STATISTICS } from 'redux/actionTypes';

const initialState = {};

const handlers = {
  [STATISTICS.GET_DATA]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
};

export default createReducer(initialState, handlers);
