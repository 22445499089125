import React from 'react';

// Load Components
import { Link, NavLink } from 'react-router-dom';

// Load Icons
import { CarretDown, LogoWmBlack } from 'shared/components/Icons';

import { CONSTS } from 'configs';
import NotificationsDropdown from './_NotificationsDropdown';
import UserDropdown from './_UserDropdown';

const AppHeader = () => (
  <div className='d-flex align-items-center justify-content-between h-100'>
    <Link to='/dashboard'>
      <LogoWmBlack width={132} height={28} />
    </Link>
    <nav className='nav'>
      <ul>
        {CONSTS.headerNavigation.map((group, groupI) => (
          <li key={`${group.name}${groupI}`}>
            <NavLink to={group.to} className='nav-parent'>
              {group.name}
            </NavLink>
            {!!group.childs?.length && (
              <>
                <CarretDown width={20} height={20} />
                <ul>
                  {group.childs.map((child, childI) => (
                    <li key={`${child.name}${groupI}${childI}`} className='nav-sub-menu'>
                      <NavLink to={child.to} className='nav-child'>
                        {child.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </li>
        ))}
      </ul>
    </nav>
    <div className='d-flex align-items-center justify-content-end mw-132-px'>
      <NotificationsDropdown />
      <UserDropdown />
    </div>
  </div>
);

export default AppHeader;
