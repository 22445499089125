import React from 'react';

// Load Components
import Checkbox from './Checkbox';

// Load Vendors
import classNames from 'classnames';

const CheckboxLabel = ({ checked, onChange, label, labelClass }) => {
  const classes = classNames('checkbox-label', { active: checked });

  return (
    <label className={classes}>
      <Checkbox className='mb-0 mr-2' checked={checked} onChange={onChange} />
      <span className={labelClass}>{label}</span>
    </label>
  );
};

export default CheckboxLabel;
